import { Dispatch } from 'react';

import { Cookies } from 'react-cookie';

import AuthService from 'src/services/auth.service';

import { ILoginInput, ILoginResponse, IUser } from 'src/interfaces/user';
import { request } from 'src/utils';

import {
  SIGN_IN,
  LOGOUT_USER,
  FETCH_USER,
  FETCH_USER_CELLS,
  AuthActionTypes,
} from './types';

export const signIn =
  (input: ILoginInput) => async (dispatch: Dispatch<AuthActionTypes>) => {
    const cookies = new Cookies();

    // const response = {
    //   accessToken: 'true',
    //   user: {
    //     name: 'Evelin',
    //     email: 'evelin@vestsurf.com.br',
    //     cell: 'CELULA 100',
    //     canCreate: true,
    //     canEdit: true,
    //     canDelete: true,
    //     canPrint: true,
    //   },
    // };

    try {
      const response: ILoginResponse = await AuthService.signIn(input);

      if (response) {
        dispatch({
          type: SIGN_IN,
          payload: response,
        });

        cookies.set('_jwtToken', response?.accessToken || undefined, {
          path: '/',
          maxAge: 86400,
        });
      }

      return response;
    } catch (e) {
      request.errors(e);
    }
  };

export const verifyIdentity =
  () => async (dispatch: Dispatch<AuthActionTypes>) => {
    const cookies = new Cookies();

    const token = cookies.get('_jwtToken');

    try {
      const response: IUser | any = await AuthService.verifyIdentity();

      if (response) {
        response.accessToken = token;

        dispatch({
          type: SIGN_IN,
          payload: response,
        });
      }

      return response;
    } catch (e) {
      request.errors(e);
    }
  };

export const fetchUser = () => async (dispatch: Dispatch<AuthActionTypes>) => {
  try {
    const response: IUser = await AuthService.me();

    dispatch({
      type: FETCH_USER,
      payload: response,
    });

    return response;
  } catch (e) {
    request.errors(e);
  }
};

export const fetchCells = () => async (dispatch: Dispatch<AuthActionTypes>) => {
  try {
    const response = await AuthService.fetchCells();

    dispatch({
      type: FETCH_USER_CELLS,
      payload: response,
    });

    return response;
  } catch (err) {
    throw err;
  }
};

export const logout = () => async (dispatch: Dispatch<AuthActionTypes>) => {
  const cookies = new Cookies();

  cookies.set('_jwtToken', undefined, {
    path: '/',
    maxAge: 0,
  });

  dispatch({
    type: LOGOUT_USER,
  });
};
