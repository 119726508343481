import React from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, Row, Col, Avatar } from 'antd';

import { BsGraphUp, BsBoxArrowRight } from 'react-icons/bs';

import { logout } from 'src/store/auth/actions';
import { images, wordWrap } from 'src/utils';

import Divider from './components/divider/divider.component';
import { IProps } from './sider.type';
import { LogoIcon } from './sider.style';
import { MobileView, BrowserView } from 'react-device-detect';

const LayoutSider: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  const { collapsed, user } = props;

  const handleLogout = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | any
  ) => {
    props.logout();
  };

  const collapsedTitle = (text: string, collapsed: boolean) => {
    if (!collapsed) {
      return text;
    }
    return;
  };

  return (
    <>
      <BrowserView>
        <Link to="/">
          <LogoIcon
            src={images.icons.default}
            alt="logo"
            title="logo"
            width="45px"
            collapsed={collapsed}
          />
        </Link>
      </BrowserView>

      <MobileView>
        <Row gutter={16} className="my-2 px-3">
          <Col>
            <Avatar shape="square" size="large" src={images.icons.default} />
          </Col>
          <Col>
            <p className="text-white mb-0">{user?.name || 'Administrador'}</p>
            <span className="d-block" style={{ color: '#666' }}>
              {wordWrap(user?.email, 20)}
            </span>
          </Col>
        </Row>
      </MobileView>

      <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
        <Divider content={collapsedTitle('Dashboard', collapsed)} noBorder />
        <Menu.Item
          key="1"
          icon={<BsGraphUp />}
          onClick={() => navigate('/painel/')}
        >
          Consultar
        </Menu.Item>

        <Menu.Item key="8" icon={<BsBoxArrowRight />} onClick={handleLogout}>
          Sair
        </Menu.Item>
      </Menu>
    </>
  );
};

export default connect(undefined, { logout })(LayoutSider);
