import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import moment from 'moment';

import { IReduxState } from 'src/store';
import { Notification } from 'src/utils';

import { IProps } from './main.type';
import { IReportInput } from 'src/interfaces/report';
import { CLEAR_CURRENT_REPORT } from 'src/store/report/types';

const useMain = (props: IProps) => {
  const [modal, contextHolder] = Modal.useModal();
  const [sending, setSending] = useState(false);
  const dispatch = useDispatch();

  const orientation = useSelector(
    (state: IReduxState) => state.config.orientation
  );

  const openModal = () => {
    if (orientation !== 'landscape') {
      modal.warning({
        title: 'Ops, parece que seu celular está em modo retrato!',
        content: `Ative o modo paisagem e gire o celular para visualizar corretamente os relatórios.`,
        centered: true,
        closeIcon: null,
        maskClosable: false,
        keyboard: false,
        okButtonProps: {
          type: 'default',
          size: 'small',
        },
      });
    }
  };

  const handleSubmit = async (values: IReportInput) => {
    setSending(true);
    openModal();

    try {
      await props.fetchReport({
        ...values,
        date: moment().format('YYYY-M-D'),
      });
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar o relatório, tente novamente mais tarde.',
      });
      return false;
    } finally {
      setSending(false);
    }
  };

  const clearCurrentReport = () => {
    dispatch({
      type: CLEAR_CURRENT_REPORT,
    });
  };

  return { handleSubmit, sending, contextHolder, clearCurrentReport };
};

export default useMain;
