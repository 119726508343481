import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import moment from 'moment';

import {
  CLEAR_CURRENT_REPORT,
  FETCH_REPORT,
  FILTER_UPDATE,
  IReducerReportState,
  ReportActionTypes,
} from './types';

const initialState: IReducerReportState = {
  filter: {
    reportType: undefined,
    cellId: undefined,
    date: moment().format('YYYY-M-D'),
  },
  current: undefined,
  backupOperationReport: undefined,
  backupOperatorReport: undefined,
};

const persistConfig = {
  storage,
  key: 'reports',
  whitelist: [
    'filter',
    'current',
    'backupOperationReport',
    'backupOperatorReport',
  ],
};

const reducers = (state = initialState, action: ReportActionTypes) => {
  switch (action.type) {
    case FILTER_UPDATE:
      return {
        ...state,
        filter: action.payload,
      };

    case FETCH_REPORT:
      return {
        ...state,
        current: action.payload,
        backupOperatorReport:
          action.payload.reportType === 'operator' ? action.payload : undefined,
        backupOperationReport:
          action.payload.reportType === 'operation'
            ? action.payload
            : undefined,
      };

    case CLEAR_CURRENT_REPORT:
      return {
        ...state,
        current: undefined,
      };
    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducers);
