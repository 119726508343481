export const UPDATE_ORIENTATION = 'UPDATE_ORIENTATION';
export const UPDATE_CONNECTION = 'UPDATE_CONNECTION';
export const SW_INIT = 'SW_INIT';
export const SW_UPDATE = 'SW_UPDATE';

type TOrientation = 'portrait' | 'landscape';

export interface IReducerConfigState {
  orientation: TOrientation;
  connected: boolean;
  serviceWorkerInitialized: boolean;
  serviceWorkerRegistration?: ServiceWorkerRegistration;
  serviceWorkerUpdated: boolean;
}

export interface IUpdateOrientation {
  type: typeof UPDATE_ORIENTATION;
  payload: TOrientation;
}
export interface IUpdateConnection {
  type: typeof UPDATE_CONNECTION;
  payload: boolean;
}

export interface ISwInit {
  type: typeof SW_INIT;
}

export interface ISwUpdate {
  type: typeof SW_UPDATE;
  payload: ServiceWorkerRegistration;
}

export type ConfigActionTypes =
  | IUpdateConnection
  | IUpdateOrientation
  | ISwInit
  | ISwUpdate;
