import { useState } from 'react';

import { Notification } from 'src/utils';
import { ICell } from 'src/interfaces/user';

import { IProps } from './filter.type';

const useFilter = (props: IProps) => {
  const [loading, setLoading] = useState(true);
  const [cells, setCells] = useState<ICell[] | undefined>(undefined);

  const handleCells = async () => {
    setLoading(true);

    try {
      const response = await props.fetchCells();
      
      if (response) {
        setCells(response);
      }
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar as células, tente novamente mais tarde.',
      });

      return false;
    } finally {
      setLoading(false);
    }
  };

  return { handleCells, loading, cells };
};

export default useFilter;
