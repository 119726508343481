import { IReport, IReportInput } from 'src/interfaces/report';

export const FILTER_UPDATE = 'FILTER_UPDATE';
export const FETCH_REPORT = 'FETCH_REPORT';
export const CLEAR_CURRENT_REPORT = 'CLEAR_CURRENT_REPORT';

export interface IReducerReportState {
  filter: IReportInput;
  current?: IReport;
  backupOperatorReport?: IReport;
  backupOperationReport?: IReport;
}

export interface IFilterUpdate {
  type: typeof FILTER_UPDATE;
  payload: IReportInput;
}

export interface IClearCurrentReport {
  type: typeof CLEAR_CURRENT_REPORT;
}

export interface IFetchReport {
  type: typeof FETCH_REPORT;
  payload: IReport ;
}

export type ReportActionTypes =
  | IFilterUpdate
  | IFetchReport
  | IClearCurrentReport;
