import styled from 'styled-components';

export const Divider = styled.div`
  background: #313131;
  content: '';
  height: 1px;
  width: 80%;
  margin: 10px auto 0;
`;

export const Text = styled.p`
  color: #aaa;
  font-size: 12px;
  letter-spacing: 0.07em;
  line-height: 30px;
  margin: 10px auto 0;
  padding-left: 15px;
  text-transform: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;
