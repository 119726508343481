import styled from 'styled-components';

export const LogoIcon = styled.img<{ collapsed: boolean }>`
  display: block;
  height: auto;
  margin: 20px auto 20px;
  transition: all 0.3s ease-in;
`;

export const LogoWide = styled.img<{ collapsed: boolean }>`
  display: block;
  height: auto;
  margin: 20px auto 20px;
  transition: all 0.3s ease-in;
  ${(props: any) =>
    props.collapsed ? 'opacity: 0; display:none;' : 'opacity: 1;'}
`;

const Default = {
  sideMenu: {
    borderRight: 0,
    height: '100%',
  },
};
export default Default;
