import { Tag } from 'antd';
import moment from 'moment';

import { tagColor } from 'src/utils/helper/functions.helper';

const hourTag = (hours: any, record: any, type: string, target?: number) => {
  const value =
    record.hoursInterval.find((item: any) => {
      return Number(item.hour) === Number(hours);
    })?.quantity || 0;

  const hourTarget = type === 'operator' ? record?.hourTarget : target;

  return (
    <Tag
      key={record.id}
      color={tagColor(value, hourTarget, 'hourTarget')}
      className="m-0"
    >
      <b>{value}</b>
    </Tag>
  );
};

const hoursInterval = (type: string, target?: number) => {
  const allHours = [
    7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0,
  ];

  const displayHour: any[] = [];

  allHours.forEach((item: any) => {
    if (moment(item).isSame(moment(), 'day')) {
      if (item <= moment().hours()) {
        displayHour.push({
          key: `${item}:00`,
          title: `${item}:00`,
          dataIndex: item,
          width: 60,
          render: (text: any, record: any) =>
            hourTag(item, record, type, target),
        });
      }
    } else {
      displayHour.push({
        key: `${item}:00`,
        title: `${item}:00`,
        dataIndex: item,
        width: 60,
        render: (text: any, record: any) => hourTag(item, record, type, target),
      });
    }
  });

  return displayHour;
};

export const operatorColumns = [
  {
    title: 'Cód.',
    dataIndex: 'operatorId',
    key: 'operatorId',
    fixed: 'left',
    width: 50,
  },
  {
    title: 'Operação',
    dataIndex: 'operation',
    key: 'operation',
    fixed: 'left',
    width: 100,
    render: (text: any, record: any) =>
      `${record?.operationId} - ${record?.operationName}`,
  },
  {
    title: 'Meta',
    dataIndex: 'hourTarget',
    key: 'hourTarget',
    width: 70,
    fixed: 'left',
    className: 'justify-content-center',
    render: (text: string) => (
      <Tag color="blue" className="m-0">
        <b>{Math.trunc(Number(text))}</b>
      </Tag>
    ),
  },
  ...hoursInterval('operator'),
  {
    title: 'Total',
    key: 'total',
    width: 70,
    fixed: 'right',
    render: (text: any, record: any) => {
      return (
        <Tag
          color={tagColor(record.total, record.hourTarget, 'total')}
          className="m-0"
        >
          <b>{record.total}</b>
        </Tag>
      );
    },
  },
];

export const operationColumns = (target: number) => [
  {
    title: 'Cód.',
    dataIndex: 'operationId',
    key: 'operationId',
    fixed: 'left',
    width: 50,
  },
  {
    title: 'Operação',
    dataIndex: 'operationName',
    key: 'operationName',
    fixed: 'left',
    width: 100,
  },
  ...hoursInterval('operation', target),
  {
    title: 'Total',
    key: 'total',
    width: 70,
    fixed: 'right',
    render: (text: any, record: any) => {
      return (
        <Tag color={tagColor(record.total, target, 'total')} className="m-0">
          <b>{record.total}</b>
        </Tag>
      );
    },
  },
];
