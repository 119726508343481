import { Dispatch } from 'react';
import moment from 'moment';

import ReportService from 'src/services/report.service';
import { IReportInput } from 'src/interfaces/report';
import { store } from 'src/store';

import { FETCH_REPORT, ReportActionTypes } from './types';

export const fetchReport =
  (values: IReportInput) => async (dispatch: Dispatch<ReportActionTypes>) => {
    let data;

    const backupOperation =
      store.getState().reports?.backupOperationReport || undefined;

    const backupOperator =
      store.getState().reports?.backupOperatorReport || undefined;

    if (
      !!backupOperator &&
      values.reportType === 'operator' &&
      values.cellId === backupOperator?.cell?.id &&
      moment(backupOperator?.updateAt).isAfter(moment().subtract(10, 'minutes'))
    ) {
      data = backupOperator;
    } else if (
      !!backupOperation &&
      values.reportType === 'operation' &&
      values.cellId === backupOperation?.cell?.id &&
      moment(backupOperation?.updateAt).isAfter(
        moment().subtract(10, 'minutes')
      )
    ) {
      data = backupOperation;
    }

    try {
      if (!data) {
        const response: any = await ReportService.current(values);

        data = {
          reportType: values?.reportType,
          cell: response?.cell,
          updateAt: moment().toString(),
          data: response.productions,
        };
      }

      dispatch({
        type: FETCH_REPORT,
        payload: data,
      });

      return data;
    } catch (err) {
      console.dir(err, 'erro');
      throw err;
    }
  };
