import React from 'react';
import { Row, Col, Card, Button } from 'antd';
import { connect } from 'react-redux';
import { FcSynchronize } from 'react-icons/fc';

import { Container, Title } from 'src/components';
import { fetchReport } from 'src/store/report/actions';

import TableComponent from './components/table/table.component';
import FilterComponent from './components/filter/filter.component';
import { IProps } from './main.type';
import useMain from './main.use';
import { IReduxState } from 'src/store';

const MainScreen: React.FC<IProps> = (props) => {
  const { report } = props;

  const { handleSubmit, sending, contextHolder, clearCurrentReport } =
    useMain(props);

  return (
    <Container type="fluid">
      {!!report ? (
        <Row
          gutter={16}
          justify="center"
          align="top"
          style={{ minHeight: '90vh' }}
          className=" pb-0"
        >
          <Col span={24}>
            <Title
              title={
                <span>
                  Exibindo relatórios de produção: modo{' '}
                  <b>
                    {report?.reportType === 'operator'
                      ? 'OPERADOR'
                      : 'OPERAÇÃO'}
                  </b>
                </span>
              }
              subtitle={
                <Row align="middle">
                  Clique para gerar um novo relatório
                  <Button
                    size="small"
                    className="d-flex align-items-center mx-2"
                    onClick={() => clearCurrentReport()}
                  >
                    <FcSynchronize />
                  </Button>
                </Row>
              }
            />
          </Col>
          <Col span={24}>
            <TableComponent report={report} />
          </Col>
        </Row>
      ) : (
        <Row gutter={16} justify="space-between">
          <Col span={24}>
            <Card className="my-3">
              <Title
                foreword="BEM-VINDO"
                title={
                  <span>
                    Bem-vindo ao app de <b>controle de produção</b>
                  </span>
                }
                subtitle="Clique no botão abaixo para gerar o relatório."
              />
            </Card>
          </Col>
          <Col span={24}>
            <FilterComponent handleSubmit={handleSubmit} sending={sending} />
          </Col>
        </Row>
      )}
      {contextHolder}
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  report: state.reports.current,
});

export default connect(mapStateToProps, { fetchReport })(MainScreen);
