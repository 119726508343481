import moment from 'moment';

export const wordWrap = (text: string, number: number) => {
  let data: string = text;

  if (text.length > number) {
    data = text.substring(0, number) + '[...]';
  }

  return data;
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const tagColor = (value: number, hourTarget: number, type: string) => {
  let target = hourTarget;

  if (type === 'total') {
    target = moment().day() === 5 ? hourTarget * 8 : hourTarget * 9;
  }

  if (value !== 0) {
    if (Math.trunc(value) >= Math.trunc(target)) {
      return 'green';
    } else if (Math.trunc(value) >= Math.trunc(target) * 0.8) {
      return 'orange';
    } else {
      return 'red';
    }
  }

  return 'default';
};
