import { combineReducers } from 'redux';

import configReducer from './config/reducers';
import authReducer from './auth/reducers';
import reportReducer from './report/reducer';

export default combineReducers({
  config: configReducer,
  auth: authReducer,
  reports: reportReducer,
});
