import React, { useEffect } from 'react';
import { Card, Form, Select, Skeleton, Button } from 'antd';
import { connect } from 'react-redux';

import { fetchCells } from 'src/store/auth/actions';
import { ICell } from 'src/interfaces/user';

import { IProps } from './filter.type';
import useFilter from './filter.use';

const { Option } = Select;

const FilterScreen: React.FC<IProps> = (props) => {
  const { handleCells, cells, loading } = useFilter(props);
  const { handleSubmit, sending } = props;

  useEffect(() => {
    handleCells();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="mb-3 py-3">
      {loading ? (
        <Skeleton active />
      ) : (
        <Form layout="vertical" size="small" onFinish={handleSubmit}>
          <Form.Item
            name="cellId"
            rules={[{ required: true }]}
            label={
              <span>
                Selecione a <b>célula:</b>
              </span>
            }
          >
            <Select>
              {cells?.map((item: ICell) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="reportType"
            rules={[{ required: true }]}
            label={
              <span>
                Selecione o tipo de <b>gráfico:</b>
              </span>
            }
          >
            <Select>
              <Option value="operator">Gráfico Operador</Option>
              <Option value="operation">Gráfico Operação</Option>
            </Select>
          </Form.Item>
          <Button
            loading={sending}
            htmlType="submit"
            type="primary"
            size="small"
            style={{ fontSize: 12, margin: 'auto' }}
          >
            Gerar relatório
          </Button>
        </Form>
      )}
    </Card>
  );
};

export default connect(undefined, { fetchCells })(FilterScreen);
