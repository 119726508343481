import { IUser, ICell, ILoginResponse } from 'src/interfaces/user';

export const SIGN_IN = 'SIGN_IN';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_CELLS = 'FETCH_USER_CELLS';
export const LOGOUT_USER = 'LOGOUT_USER';

export interface IReducerAuthState {
  user?: IUser | any;
  token?: any;
}

export interface ISignInUser {
  type: typeof SIGN_IN;
  payload: ILoginResponse;
}

export interface IFetchUser {
  type: typeof FETCH_USER;
  payload: IUser;
}

export interface IFetchCell {
  type: typeof FETCH_USER_CELLS;
  payload: ICell[];
}

export interface ILogoutUser {
  type: typeof LOGOUT_USER;
}

export type AuthActionTypes =
  | ISignInUser
  | IFetchUser
  | IFetchCell
  | ILogoutUser;
