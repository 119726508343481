import { api } from './';

import { IReportInput } from 'src/interfaces/report';

const ReportService = {
  current: async ({ reportType, cellId, date }: IReportInput) => {
    const res = await api.get(`/reports/productions`, {
      params: { reportType, cellId, date },
    });

    return res.data;
  },
};

export default ReportService;
