import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { CookiesProvider } from 'react-cookie';

import 'moment/locale/pt-br';
import 'src/assets/styles/antd.css';
import 'src/assets/styles/main.scss';

import { store, persistor } from 'src/store';
import App from 'src/screens/app/app.component';

// import * as serviceWorker from './serviceWorker';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </CookiesProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
