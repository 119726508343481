import React from 'react';
import { Table, Tag, Row, Result, Card } from 'antd';
import moment from 'moment';

import { table } from 'src/utils';

import { operatorColumns, operationColumns } from './table.utils';
import { IProps } from './table.type';

const TableComponent: React.FC<IProps> = ({ report }) => {
  const header = () => (
    <span>
      <Row gutter={16} justify="space-between" align="middle">
        <div className="px-2">
          <b>{report?.cell?.name}</b> | {moment().format('DD/MM/YYYY')}
        </div>

        {report?.reportType === 'operation' && (
          <div className="px-2">
            <Tag color="green" className="m-0">
              Meta: <b>{report?.cell?.target}</b>
            </Tag>
          </div>
        )}

        <div style={{ fontSize: 12 }}>
          Modo de exibição:{' '}
          <Tag style={{ fontSize: 12 }}>
            <b>{report?.reportType === 'operator' ? 'Operador' : 'Operação'}</b>
          </Tag>
        </div>
      </Row>
    </span>
  );

  const footer = () => (
    <span>
      <Row gutter={16} justify="space-between" align="middle">
        <div className="px-2">
          <span>
            Última atualização às {moment(report?.updateAt).format('HH:mm')}
          </span>
        </div>
      </Row>
    </span>
  );

  return (
    <div>
      {report && report?.data.length ? (
        <Table
          {...table}
          title={header}
          footer={footer}
          columns={
            report.reportType === 'operation'
              ? operationColumns(Number(report?.cell?.target) || 0)
              : operatorColumns
          }
          dataSource={report?.data || []}
          scroll={{ x: 1000, y: 'auto' }}
          pagination={false}
          className="mb-3"
        />
      ) : (
        <Card>
          <Result
            status="warning"
            title="Ocorreu um erro ao buscar as informações, tente novamente mais tarde."
          />
        </Card>
      )}
    </div>
  );
};

export default TableComponent;
